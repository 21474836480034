/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@layer components {
  html {
    @apply font-sans;
    padding-top: constant(safe-area-inset-top); // for ios 13+
    padding-top: env(safe-area-inset-top); // for previous ios versions
  }


  h1 {
    @apply font-bold text-3xl md:text-5xl text-green-700;
  }
  .h1span{
    @extend h1;
  }
  h2 {
    @apply font-bold text-2xl md:text-4xl;
  }
  .h2span{
    @extend h2;
  }
  h3 {
    @apply font-bold text-xl md:text-3xl;
  }
  .h3span{
    @extend h3;
  }
  h4 {
    @apply text-base md:text-xl;
  }
  .h4span{
    @extend h4;
  }
  h5{
    @apply text-base md:text-lg
  }
  .h5span{
    @extend h5;
  }
  .standardTextSize {
    @apply text-sm md:text-base;
  }
  .text-box {
    @extend .standardTextSize;
    @apply mt-1 block w-full rounded-md border-grey-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-300 focus:ring-opacity-50;    
    &.error {
      @apply border-red-600 focus:border-red-300 focus:ring focus:ring-red-100 focus:ring-opacity-50 bg-red-100;
    }
    &:disabled{
      @apply bg-grey-100;
    }
    resize: none;
  }
  textarea{
    @extend .text-box;
    resize:none;
  }

  .form-error {
    @apply py-2 text-red-600 text-sm font-bold;
  }
  .btn-primary {
    @extend .standardTextSize;
    @apply bg-green-300 text-offwhite-500 px-4 py-2 rounded transition-all duration-200 border-green-300 border-solid border-[1px];

    &:hover {
      @apply bg-green-700 border-green-700 border-solid border-[1px];
    }

    &:disabled {
      @apply bg-lime-500 cursor-default border-lime-500 border-solid border-[1px];
    }
  }
  .btn-primary-full {
    @extend .btn-primary;
    @apply w-full;
  }
  .btn-negative{
    @extend .standardTextSize;
    @apply bg-red-600 text-offwhite-500 px-4 py-2 rounded transition-all duration-200 border-red-600 border-solid border-[1px];
    &:hover {
      @apply border-solid border-offblack  bg-red-500;
      
    }

    &:disabled {
      @apply bg-red-300 cursor-default;
    }
  }
  .btn-inverse{
    @extend .standardTextSize;
    @apply bg-standardwhite text-green-700 px-4 py-2 rounded transition-all duration-200 border-solid border-[1px] border-green-700 rounded-md;
    &:hover {
      @apply bg-green-400 text-standardwhite;
    }
  }
  .btn-selected{
    @extend .btn-primary;
    @apply border-solid border-[1px] border-green-700 rounded-md;
  }
  .btn-negative-full {
    @extend .btn-primary;
    @apply w-full;
  }
  
  .striped-list li {
    @apply bg-grey-100;
    outline: 1px solid;
    border-radius: 1px;
    @apply outline-grey-300;
    &:nth-child(even) {
      @apply bg-grey-300;
    }
    &.highlighted {
      @apply bg-green-400;
      @apply text-standardwhite;
    }
    &.title {
      font-weight: bold;
    }
  }
  .btn-warning{
    @extend .standardTextSize;
    @apply bg-yellow-300 text-offblack px-4 py-2 rounded transition-all duration-200;
    &:hover {
      @apply border-solid border-offblack  text-standardwhite bg-yellow-700;
    }

    &:disabled {
      @apply bg-yellow-700 cursor-default;
    }
  }
  /*
    .btn-secondary-full{
        @apply  text-teal-900 px-4 py-2 rounded-md w-full mt-8 transition-all duration-200 border-2 border-teal-900;


    
        &:hover{
            @apply bg-darkblue-500 text-offwhite border-transparent;
        }

        &:disabled{
            @apply bg-teal-500 cursor-default;
        }
    }
    */
  .btn-error {
    @apply bg-red-600 text-offwhite-500 px-4 py-2 rounded w-full mt-8 transition-all duration-200;
    &:hover {
      @apply bg-lime-500;
    }

    &:disabled {
      @apply bg-red-300 cursor-default;
    }
  }
  p {
    @extend .standardTextSize;
  }
  span{
    @extend .standardTextSize;
  }
  label{
    @extend .standardTextSize;    
  }
  input{
    @extend .standardTextSize;
  }
  input[type="checkbox"] {
    @apply outline-green-700 shadow-grey-900;

}
  option{
    @extend .standardTextSize;
  }
  .hover-for-select{
    @apply bg-green-400 text-standardwhite cursor-pointer;
  }
  .selected{
    @apply bg-green-400 text-standardwhite; 
}
  .badge {
    @apply rounded-full bg-standardwhite text-green-700 border border-green-300 inline-block absolute top-[-10px] right-[-10px] w-[25px] h-[25px];
  }
  .error-badge {
    @extend .badge;
    @apply bg-red-600 border-red-300 w-[28px] h-[28px] top-[-0px] right-[-0px];
  }
  .primary-green-border {
    @apply border-solid border-[1px] border-green-700;
  }


}
 @media print {
  #pnlMenu{
    visibility:hidden;
  }
  header nav, footer {
    display: none;
    }
    .print-hidden{
      //visibility: hidden;
      display:none;
    }
    .print-block{
      display: block;
    }
    .print-flex{
      @apply flex;
    }
    .print-overflow{
      overflow: visible;
    }
    .print-no-margin{
      margin: 0;
    }
    .print-no-padding{
      padding: 0;
    }
    .print-container{
      width: auto;
      height: auto;
    }
    .print-auto-height{      
      height: auto;
    }
    .container{
      width:auto;
      max-width: none;
    }
    .print-flex-row{
      flex-direction: row;
      
    }
    .print-w-80{
      width: 80%;
    }
    .print-mt-1{
      @apply mt-1;
    }
    .print-mb-1{
      margin-bottom:0.25rem;
    }
    .print-ml-1{
      margin-left:0.25rem;
    }
    .print-mr-1{
      margin-right:0.25rem;
    }
    .print-ml-6{
      @apply ml-6;
    }
    .print-justify-start{
      justify-content: start;
    }
   .print-max-h-40{
    max-height: 10rem;
   }
   .print-text-decoration-none{
    text-decoration: none;
   }
   .print-text-xs{
    @apply text-xs;
   }   
   .-print-mt-3{
    @apply -mt-3;
   }
   .-print-mt-4{
    @apply -mt-4;
   }
   .print-mx-5{
    @apply mx-5
   }

   .print-no-break{
    break-inside: avoid;
   }
   .print-remove-border{
    border:none;
   }
   .print-table-cell{
    @apply table-cell
   }
 }
 @page{
  margin:1rem;
  
  margin-top: 4rem;
 }
 @page:first{
  margin-top:-3rem;
 }